import styled from "@emotion/styled";

export const ResultPageContainer = styled.div`
    box-sizing: border-box;
    padding:0px 20px;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`